document.addEventListener('DOMContentLoaded', function () {
  if (window?._THULEDATA?.IsEditing || window?._EXTRANETDATA?.IsEditing) {
    return;
  }

  let cookieSettingsLinks = document.querySelectorAll('.cookie-settings-link');

  if (!cookieSettingsLinks) return;

  cookieSettingsLinks.forEach((link) => {
    link.addEventListener('click', (_) => Cookiebot.renew());
  });
});
